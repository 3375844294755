<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <BCol
      md="12"
      class="d-flex gap-10 w-100 p-0 my-[24px]"
    >
      <BDropdown
        id="dropdown-1"
        class="filter"
        no-caret
        variant="primary"
        toggle-class="text-decoration-none toggle-custom button-primary rounded-lg py-0 px-1"
      >
        <h5>Filter</h5>
        <template #button-content>
          <b-img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg"
            class="height-[20px]"
          />
        </template>
        <BDropdown
          id="dropdown-2"
          class="w-100"
          toggle-class="text-left"
          variant="none"
          left
          no-caret
          dropright
        >
          <template #button-content>
            <span class="hover-salmon text-6 w-100">Status</span>
          </template>
          <BDropdownForm style="width: 200px">
            <BFormCheckbox
              v-for="(status, index) in filterOptions"
              :key="index"
              v-model="filterSelected"
              :name="`checkbox-${index}`"
              class="text-6 mb-1"
              :value="status.value"
              @change="getListData()"
            >
              {{ status.text }}
            </BFormCheckbox>
          </BDropdownForm>
        </BDropdown>
        <BDropdown
          id="dropdown-3"
          class="w-100"
          toggle-class="text-left"
          variant="none"
          left
          no-caret
          dropright
        >
          <template #button-content>
            <span class="hover-salmon text-6 w-100">Periode</span>
          </template>
          <MonthlyPicker
            v-model="periode"
            :month-labels="monthlabel"
            date-format="MMM YYYY"
            place-holder="Pilih Periode"
            @selected="getListData()"
          />
        </BDropdown>
        <h5
          class="pb-0 pt-1 font-bold text-danger w-100 cursor-pointer"
          @click="resetFilter()"
        >
          Reset filter
        </h5>
      </BDropdown>
      <div class="d-flex rounded-lg align-items-center border search-bar">
        <span
          class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
        />
        <BFormInput
          v-model="keyword"
          placeholder="Cari Nama atau No Partner"
          class="border-0"
          @input="handleSearch()"
        />
      </div>
      <BButton
        tag="router-link"
        :to="{
          name: $route.meta.routeCreate,
        }"
        variant="primary"
        class="ml-auto px-3"
      >
        <strong>Tambah</strong>
      </BButton>
    </BCol>
    <div class="d-flex py-[20px] justify-end border-t">
      <span class="text-8 text--dark font-bold">Total Nominal: {{ IDR(totalNominal) }}</span>
    </div>
    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 405px); overflow-y: scroll;"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
          :small="true"
        >
          <template #cell(id)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(nominal)="data">
            <div class="flex flex-col gap-2 items-center">
              <span>{{ IDR(data.item.nominal) }}</span>
              <span
                :class="data.item.is_early_payment ? 'status-green' : 'status-blue'"
                class="status rounded-full"
              >{{ data.item.is_early_payment ? 'Pembayaran Awal' : 'Pembayaran Akhir' }}</span>
            </div>
          </template>
          <template #cell(partner_name)="data">
            <div class="d-flex flex-col">
              <span class="text-8 font-[600]">{{ data.item.partner_name }}</span>
              <span class="text-6">{{ data.item.no_partner }}</span>
            </div>
          </template>
          <template #cell(invoice_paid)="data">
            <BProgress
              height="24px"
              :max="data.item.detail_invoices.length"
              variant="success"
            >
              <BProgressBar
                :value="data.item.detail_invoices.filter((val) => val.invoice_is_paid).length"
                :label="`${data.item.detail_invoices.filter((val) => val.invoice_is_paid).length} / ${data.item.detail_invoices.length}`"
              />
            </BProgress>
          </template>
          <template #cell(published)="data">
            <span
              v-if="data.item.is_publish"
              class="k-tick-circle h-100 font-bold text-12"
              style="color: #34A770;"
            />
            <BButton
              v-else
              v-b-modal.confirmationPublish
              size="md"
              variant="outline-primary"
              @click="selectItem(data.item.id)"
            >
              Publish
            </BButton>
          </template>
          <template #cell(paid)="data">
            <span
              v-if="data.item.is_paid"
              class="k-tick-circle h-100 font-bold text-12"
              style="color: #34A770;"
            />
            <span
              v-else
              class="k-minus-cirlce h-100 font-bold text-12"
              style="color: #FBA63C;"
            />
          </template>
          <template #cell(action)="data">
            <BButton
              v-if="data.item.is_publish"
              class="btn-icon"
              size="sm"
              variant="flat-warning"
              :to="data.item.is_paid ?
                {
                  name: $route.meta.routeMonitor,
                  params: { id: data.item.id }
                } : {
                  name: $route.meta.routeDisbursement,
                  params: { id: data.item.id }
                }"
              tag="router-link"
            >
              <span
                class="k-arrow-right h-100 font-bold text-12"
                style="color: #08A0F7;"
              />
            </BButton>
            <BButton
              v-else
              class="btn-icon"
              size="sm"
              variant="flat-warning"
              :to="{
                name: $route.meta.routeEdit,
                params: { id: data.item.id }
              }"
              tag="router-link"
            >
              <span
                class="k-edit h-100 font-bold text-12"
                style="color: #FBA63C;"
              />
            </BButton>
          </template>
        </BTable>
      </div>
    </BOverlay>
    <ConfirmationPublish
      :loading-submit="loadingSubmit"
      @publish="publish"
    />
  </div>
</template>

<script>
import { komtimAxiosIns } from '@/libs/axios'
import { IDR } from '@/libs/currency'
import { alertError, alertSuccess } from '@toast'
import { LABELMONTH, YEAR_MONTH } from '@/libs/filterDate'
import MonthlyPicker from 'vue-monthly-picker'
import { tableActive, filterOptions } from '../config'
import ConfirmationPublish from '../modal/ConfirmationPublish.vue'

export default {
  components: {
    ConfirmationPublish,
    MonthlyPicker,
  },
  data() {
    return {
      filterOptions,
      periode: null,
      keyword: '',
      fields: tableActive,
      loading: false,
      loadingSubmit: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      idItem: 0,
      filterSelected: [],
      totalNominal: 0,
      alertError,
      alertSuccess,
      monthlabel: LABELMONTH,
      IDR,
    }
  },
  computed: {
    status() {
      return this.filterSelected.join(',')
    },
  },
  mounted() {
    this.getListData()
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')

      if ((table.scrollTop >= table.scrollHeight - table.offsetHeight - 5) && !this.loading && !this.lastData) {
        this.getNextData()
      }
    },
    async getListData() {
      this.loading = true
      this.offset = 0
      const params = `type=active&offset=${this.offset}&limit=${this.limit}&keyword=${encodeURIComponent(this.keyword)}&status=${this.status}&period=${this.periode ? YEAR_MONTH(this.periode) : ''}`
      const url = `v1/invoices?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data.data
          this.totalNominal = res.data.data.total_nominal
          this.items = data
          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true
      if (!this.lastData) {
        const params = `offset=${this.offset}&limit=${this.limit}&keyword=${encodeURIComponent(this.keyword)}&status=${this.status}&period=${this.periode ? YEAR_MONTH(this.periode) : ''}`
        const url = `v1/invoices?${params}`

        await komtimAxiosIns
          .get(url)
          .then(res => {
            const { data } = res.data.data
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.alertError(err)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    handleSearch: _.debounce(function search() {
      this.getListData()
    }, 1000),
    selectItem(id) {
      this.idItem = id
    },
    resetFilter() {
      this.filterSelected = []
      this.periode = null

      this.getListData()
    },
    async publish() {
      this.loadingSubmit = true

      const url = `v1/invoices/${this.idItem}/publish`
      await komtimAxiosIns
        .put(url)
        .then(() => {
          const text = 'Berhasil publish invoice'
          this.alertSuccess(text)

          this.$bvModal.hide('confirmationPublish')
          this.getListData()
        })
        .catch(error => {
          this.alertError(error)
        })
        .finally(() => {
          this.loadingSubmit = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
.progress {
  background: #E2E2E2;
}
.status {
  padding: 1px 10px;
  text-transform: capitalize;
  font-size: small;

  &-blue {
    background: #DFF3FF;
    color: #08A0F7;
  }

  &-green {
    background: #DCF3EB;
    color: #34A770;
  }
}
</style>
